import React, { useState } from 'react';
import { render } from 'react-dom';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import './index.css';

const CsvGenerator = () => {
  const [formValues, setFormValues] = useState({
    couponCode: '',
    couponExpiry: '',
    productUrl: '',
    detailsUrl: '',
    contactInfo: '',
    termsUrl: '',
    additionalInfo: '',
    distributionStart: '',
    distributionEnd: ''
  });

  const [rowCount, setRowCount] = useState(1);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleRowChange = (e) => {
    setRowCount(parseInt(e.target.value));
  };

  const handleDownload = () => {
    const header = [
      'クーポンコード',
      'クーポン有効期限',
      'クーポンが使える商品ページのURL',
      '使用方法の詳細URL',
      'お問い合わせ先',
      '注意事項のURL',
      'その他注意事項',
      '配信開始',
      '配信終了'
    ];
  
    const csvRows = Array(rowCount).fill([
      formValues.couponCode,
      formValues.couponExpiry,
      formValues.productUrl,
      formValues.detailsUrl,
      formValues.contactInfo,
      formValues.termsUrl,
      formValues.additionalInfo,
      formatDateTime(formValues.distributionStart),
      formatDateTime(formValues.distributionEnd)
    ]);
  
    const csvData = [header, ...csvRows];
    const csvString = Papa.unparse(csvData);
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'coupon.csv');
  };

  const formatDateTime = (datetimeStr) => {
    if (!datetimeStr) return '';
    const date = new Date(datetimeStr);
    const pad = (n) => n.toString().padStart(2, '0');
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())} ${pad(date.getHours())}:${pad(date.getMinutes())}:00`;
  };

  return (
    <div className="container">
      <h2 className="title">クーポンCSV作成ツール</h2>

      <div className="form-group">
        <label>クーポンコード:</label>
        <input
          type="text"
          name="couponCode"
          value={formValues.couponCode}
          onChange={handleChange}
          className="input-field"
        />
      </div>
      <div className="form-group">
        <label>クーポン有効期限:</label>
        <input
          type="text"
          name="couponExpiry"
          value={formValues.couponExpiry}
          onChange={handleChange}
          placeholder="例: 2025年8月31日"
          className="input-field"
        />
      </div>
      <div className="form-group">
        <label>クーポンが使える商品ページのURL:</label>
        <input
          type="text"
          name="productUrl"
          value={formValues.productUrl}
          onChange={handleChange}
          className="input-field"
        />
      </div>
      <div className="form-group">
        <label>使用方法の詳細URL:</label>
        <input
          type="text"
          name="detailsUrl"
          value={formValues.detailsUrl}
          onChange={handleChange}
          className="input-field"
        />
      </div>
      <div className="form-group">
        <label>お問い合わせ先:</label>
        <input
          type="text"
          name="contactInfo"
          value={formValues.contactInfo}
          onChange={handleChange}
          className="input-field"
        />
      </div>
      <div className="form-group">
        <label>注意事項のURL:</label>
        <input
          type="text"
          name="termsUrl"
          value={formValues.termsUrl}
          onChange={handleChange}
          className="input-field"
        />
      </div>
      <div className="form-group">
        <label>その他注意事項:</label>
        <textarea
          name="additionalInfo"
          value={formValues.additionalInfo}
          onChange={handleChange}
          rows="4"
          className="textarea-field"
        />
      </div>

      <div className="form-group">
        <label>配信開始:</label>
        <input
          type="datetime-local"
          name="distributionStart"
          value={formValues.distributionStart}
          onChange={handleChange}
          className="input-field"
        />
      </div>
      <div className="form-group">
        <label>配信終了:</label>
        <input
          type="datetime-local"
          name="distributionEnd"
          value={formValues.distributionEnd}
          onChange={handleChange}
          className="input-field"
        />
      </div>

      <div className="form-group">
        <label>CSVの行数を選択:</label>
        <select value={rowCount} onChange={handleRowChange} className="select-field">
          {[...Array(100).keys()].map((i) => (
            <option key={i + 1} value={i + 1}>
              {i + 1}
            </option>
          ))}
        </select>
      </div>

      <button onClick={handleDownload} className="download-button">
        CSVをダウンロード
      </button>
    </div>
  );
};

render(<CsvGenerator />, document.getElementById('root'));
